import './App.css';
import UploadWrap from './components/UploadWrap';
import { useState } from 'react';
import Loading from './components/Loading';

function App() {
  const [file, setFile] = useState();
  const [isLoading, setIsLoading] = useState(false);

  return (
    <div
      style={{
        width: '100vw',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <UploadWrap {...{ file, setFile, setIsLoading }} />
      <Loading isLoading={isLoading} />
    </div>
  );
}

export default App;
