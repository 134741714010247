export const checkIsVideo = (type) => type && type.startsWith('video/');
export const checkIsImage = (type) => type && type.startsWith('image/');
export const checkIsAudio = (type) => type && type.startsWith('audio/');

export function getFileTypeFromFileName(fileName) {
  if (!fileName) {
    return '';
  }
  // Create a mapping of file extensions to MIME types for images and videos
  const fileTypes = {
    // Image MIME types
    jpg: 'image/jpeg',
    jpeg: 'image/jpeg',
    png: 'image/png',
    gif: 'image/gif',
    bmp: 'image/bmp',

    // Video MIME types
    mp4: 'video/mp4',
    webm: 'video/webm',
    avi: 'video/x-msvideo',
    mov: 'video/quicktime',
    wmv: 'video/x-ms-wmv',

    // Other file types (add more as needed)
    txt: 'text/plain',
    pdf: 'application/pdf',
    doc: 'application/msword',
    xls: 'application/vnd.ms-excel',
    // Add more file types as needed

    mp3: 'audio/mpeg',
    ogg: 'audio/ogg',
    m4u: 'audio/mp4',
  };

  // Get the file extension from the file name
  const fileExtension = fileName?.split('.').pop().toLowerCase();

  // Check if the extension exists in the mapping
  if (fileTypes.hasOwnProperty(fileExtension)) {
    return fileTypes[fileExtension];
  } else {
    // Return a default MIME type or handle the unknown extension as needed
    return 'application/octet-stream'; // Default binary MIME type
  }
}

export const FILE_TYPE = {
  AUDIO: 'AUDIO',
  VIDEO: 'VIDEO',
  IMAGE: 'IMAGE',
};

export const getFileTypeBaseOnMime = (mimeFile) => {
  if (checkIsAudio(mimeFile)) {
    return FILE_TYPE.AUDIO;
  }
  if (checkIsVideo(mimeFile)) {
    return FILE_TYPE.VIDEO;
  }
  if (checkIsImage(mimeFile)) {
    return FILE_TYPE.IMAGE;
  }
  return;
};

export const getFileTypeBaseOnFileName = (fileName) => {
  const mimeFile = getFileTypeFromFileName(fileName);
  if (mimeFile === 'application/octet-stream') {
    return;
  }
  return getFileTypeBaseOnMime(mimeFile);
};
