import React from 'react';
import { ThreeDots } from 'react-loader-spinner';

function Loading({ isLoading }) {
  return (
    <>
      {isLoading && (
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            display: 'flex',
            flex: 1,
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
            width: '100vw',
            height: '100vh',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 999,
          }}
        >
          <div
            style={
              {
                // backgroundColor: 'green',
                // width: 100,
              }
            }
          >
            <ThreeDots
              height="80"
              width="80"
              radius="9"
              color="#fff"
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          </div>
        </div>
      )}
    </>
  );
}

export default Loading;
