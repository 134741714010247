import React, { useRef, useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { FILE_TYPE, getFileTypeBaseOnMime } from '../utils/file';
import { SERVER_URL } from '../configs';
import { downloadFile } from '../helpers/downloadFile';
import './FileUploader.css';

let SERVER_UPLOAD_URL = SERVER_URL;
if (process.env.NODE_ENV === 'development') {
  SERVER_UPLOAD_URL = 'http://localhost:3337';
  console.log('SERVER_UPLOAD_URL: ', SERVER_UPLOAD_URL);
}

const FileUploader = ({
  onFileSelect,
  onFileSelectSuccess,
  onFileSelectError,
  onLoadEnd,
}) => {
  const handleVideoChange = (e) => {
    const file = e.target.files[0];

    // Check if a file was selected
    if (file) {
      // Check the file type (optional)
      if (file.type && file.type.startsWith('video/')) {
        // Check the file size (in bytes)
        const maxSizeInBytes = 200 * 1024 * 1024; // Set your desired maximum file size (e.g., 10 MB)

        if (file.size <= maxSizeInBytes) {
          // Handle the valid video file here
          onFileSelectSuccess(file);
          const fileInput = document.getElementById('file-upload');
          fileInput.value = '';
        } else {
          // File size exceeds the limit
          onFileSelectError({
            error:
              'The selected video file is too large. Please choose a smaller file.',
          });
        }
      } else {
        // Invalid file type
        onFileSelectError({
          error: 'Please select a valid video file.',
        });
      }
      let reader = new FileReader();
      // let url = reader.readAsDataURL(file);
      reader.onloadend = () => {
        onLoadEnd(reader.result);
      };
    }
  };

  return (
    <form
      id="file-upload-form"
      className="uploader"
      style={{
        display: 'flex',
        flex: 1,
        minWidth: 320,
      }}
    >
      <input
        id="file-upload"
        type="file"
        name="fileUpload"
        accept="video/*"
        onChange={handleVideoChange}
      />

      <label htmlFor="file-upload" style={{}}>
        {/* <img id="file-image" src="#" alt="Preview" className="hidden" /> */}
        <div id="start">
          <i className="fa fa-download" aria-hidden="true"></i>
          <div>Select a file or drag here</div>
          <div id="notimage" className="hidden">
            Please select an video
          </div>
          <span className="btn btn-primary">Select a file</span>
        </div>
        <div id="response" className="hidden">
          <div id="messages"></div>
          <progress className="progress" id="file-progress" value="0">
            <span>0</span>%
          </progress>
        </div>
      </label>
    </form>
  );
};

export const Upload = ({ onPreview, setIsLoading }) => {
  const [selectedFile, setSelectedFile] = useState(null);

  useEffect(() => {
    if (!selectedFile) {
      return;
    }
    submitForm(selectedFile);
  }, [selectedFile]);

  const submitForm = (selectedFile) => {
    const formData = new FormData();
    const filename = selectedFile?.name || '';
    formData.append('name', filename);
    formData.append('files', selectedFile);

    // console.log("formData: ", formData);

    const fileType = getFileTypeBaseOnMime(selectedFile?.type);
    // console.log('fileType: ', fileType);
    if (!fileType) {
      console.log("Don't allow file type!");
      return;
    }

    const objApis = {
      [FILE_TYPE.VIDEO]: `${SERVER_UPLOAD_URL}/upload/video`,
    };

    if (!objApis[fileType]) {
      console.log("Don't have API upload on this file type.", fileType);
      return;
    }

    // console.log('objApis[fileType]: ', objApis[fileType]);
    // console.log('process.env.NODE_ENV', process.env.NODE_ENV);

    setIsLoading(true);
    axios
      .post(objApis[fileType], formData)
      .then((res) => {
        console.log('res: ', res.data);

        const { success, file } = res?.data || {};

        if (!file?.name) {
          toast('Cannot get file name');
          return;
        }
        if (!file?.subtitleFile) {
          toast('Cannot get subtitle file');
          return;
        }

        downloadFile(
          `${SERVER_UPLOAD_URL}/${file?.subtitleFile}`,
          file?.subtitleFile
        );

        console.log(`serverUrl`, `${file?.name}`);
        onPreview({
          name: file?.name,
          type: selectedFile?.type,
        });
      })
      .catch((err) => {
        console.log('err: ', err);
      });
  };

  return (
    <FileUploader
      onFileSelectSuccess={(file) => {
        setSelectedFile(file);
      }}
      onFileSelectError={({ error }) => alert(error)}
      onLoadEnd={(result) => {
        // console.log("result:2 ", result);
      }}
    />
  );
};

const UploadWrap = ({ file, setFile, setIsLoading }) => {
  // const fileType = getFileTypeBaseOnMime(file?.type);
  // // console.log('fileUrl: ', fileUrl);
  return (
    <>
      <div
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
          // backgroundColor: 'red',
        }}
      >
        <div
          style={{
            // backgroundColor: 'yellow',
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            minWidth: 320,
          }}
        >
          <h2>Extract Subtitle</h2>
          <p></p>
          {/* <p>
            No Plugins <b>Just Javascript</b>
          </p> */}
          <Upload
            onPreview={(file) => {
              console.log('file: ', file);
              setFile(file);
              setIsLoading(false);
            }}
            {...{ setIsLoading }}
          />
        </div>
      </div>
    </>
  );
};

export default UploadWrap;
