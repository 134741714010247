import axios from 'axios';
import fileSaver from 'file-saver';

export const downloadFile = async (url, filename) => {
  try {
    const response = await axios.get(url, {
      responseType: 'blob',
    });

    const blob = new Blob([response.data], { type: 'audio/mpeg' });
    fileSaver.saveAs(blob, filename);
  } catch (error) {
    console.log('error: ', error);
  }
};
